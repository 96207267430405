/**
 * @商品相关
 * 2022-08-03 kadiam
*/
// import bossApi from '../utils/bossApi'
import bossXypq from '../utils/bossXypq'
import bossCloud from '../utils/bossCloud'

// 商品搜索 热门搜索
// export function mallProduct_hotSearch() {
//   return bossXypq({ url: 'hx.baoxian/mallProduct/hotSearch', method: 'GET' })
// }
export function mallProduct_hotSearch() {
  return bossCloud({ url: 'mall/api/mallProduct/hotSearch', method: 'GET' })
}
// 商品搜索 首页商品名称查询
// export function mallProduct_searchTagList(data) {
//   return bossXypq({ url: 'hx.baoxian/mallProduct/searchTagList', method: 'POST', data })
// }
export function mallProduct_searchTagList(data) {
  return bossCloud({ url: 'mall/api/mallProduct/searchTagList', method: 'POST', data })
}
// 商品是否禁售
export function isProhibitSale(data) {
  return bossCloud({ url: 'mall/api/mallProduct/isProhibitSale', method: 'POST', data })
}
// 商品详情
// export function mallProduct_mallProductDetailH(data) {
//   return bossXypq({ url: 'hx.baoxian/mallProduct/mallProductDetailH?productId=' + data, method: 'GET' })
// }
export function mallProduct_mallProductDetailH(data) {
  return bossCloud({ url: 'mall/api/mallProduct/detail?productId=' + data, method: 'GET' })
}
// 商品详情
export function mallProduct_mallProductDetailH_VIP(data) {
  return bossXypq({ url: 'hx.baoxian/mallVipProduct/detail?mallVipProductId=' + data, method: 'GET' })
}
// 商品到货提醒-查询
export function mallProduct_getArrivalReminder(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/getArrivalReminder', method: 'POST', data })
}
// 商品到货提醒-设置
export function mallProduct_setArrivalReminder(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/setArrivalReminder', method: 'POST', data })
}
// 商品新增收藏
export function user_collection(data) {
  return bossXypq({ url: 'hx.baoxian/user/collection/add', method: 'POST', data })
}
// 用户收藏-根据主键删除
export function user_collection_delete(data) {
  return bossXypq({ url: 'hx.baoxian/user/collection/delete/' + data, method: 'GET' })
}
// 用户收藏-批量删除
export function user_collection_deleteAll(data) {
  return bossXypq({ url: 'hx.baoxian/user/collection/deleteAll', method: 'POST', data })
}
// 首页商品分类品牌查询
// export function searchMallProductCategoryBrand(data) {
//   return bossXypq({ url: 'hx.baoxian/mallProduct/searchMallProductCategoryBrand', method: 'POST', data })
// }
export function searchMallProductCategoryBrand(data) {
  return bossCloud({ url: 'mall/api/mallProduct/categoryBrand', method: 'POST', data })
}
/**
 * @商品分类
 * 2022-08-12 kadiam
*/
// 通过id查询第三级别所有列表
export function productMallCategory_boss_generations(data) {
  return bossXypq({ url: 'hx.baoxian/productMallCategory/boss/generations?pid=' + data, method: 'GET' })
}
// 通过id查询下级分类查询
export function productMallCategory_boss_list(data) {
  return bossXypq({ url: 'hx.baoxian/productMallCategory/boss/list', method: 'POST', data })
}

/**
 * @商品推荐
 * 2023-03-08 kadiam
*/
// 商品推荐-标签列表
export function mallProduct_tagList(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/tagList', method: 'POST', data })
}
// 商品推荐-首页商品查询（商品推荐列表）
// export function searchMallProductRecommend(data) {
//   return bossXypq({ url: 'hx.baoxian/mallProduct/searchMallProductRecommend', method: 'POST', data })
// }
export function searchMallProductRecommend(data) {
  return bossCloud({ url: 'mall/api/mallProduct/recommendProductList', method: 'POST', data })
}
// 商品推荐-标签分类-子分类查询
export function tagCategoryList(data) {
  return bossXypq({ url: 'hx.baoxian/mallProduct/tagCategoryList', method: 'POST', data })
}

/**
 * @特惠卖
 * 2024-05-21 kadiam
*/
// 模块详情查询
// export function productModule_detail(data) {
//   return bossXypq({ url: 'hx.baoxian/productModule/detail', method: 'POST', data })
// }
// 通过id查询分类所有列表
export function mallPreferential_categoryList(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/categoryList', method: 'POST', data })
}
// 品牌查询
export function mallPreferential_brandList(data) {
  return bossXypq({ url: 'hx.baoxian/mallPreferential/brandList', method: 'POST', data })
}
// 购物车商品种类数量
// export function mallPreferential_cartCalculate(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/cartCalculate', method: 'POST', data })
// }
// 商品列表
// export function mallPreferential_productList(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/productList', method: 'POST', data })
// }
// 商品详情
// export function mallPreferential_productDetail(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/productDetail?productId=' + data, method: 'GET' })
// }
// 添加购物车
// export function mallPreferential_addCart(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/addCart', method: 'POST', data })
// }
// 删除购物车
// export function mallPreferential_delCart(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/delCart', method: 'POST', data })
// }
// 订单预览
// export function mallPreferential_orderPreview(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/orderPreview', method: 'POST', data })
// }
/**
 * @修改版本 2.0
 * 到微服务接口
 * 2024-11-12 kadiam 胡官韬
*/
// 模块详情查询
export function productModule_detail(data) {
  return bossCloud({ url: 'mall/api/mallProductModule/detail', method: 'POST', data })
}
// 购物车商品计算 购物车商品种类数量
export function mallPreferential_cartCalculate(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/cartCalculate', method: 'POST', data })
}
// 商品列表
export function mallPreferential_productList(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/productList', method: 'POST', data })
}
// 添加购物车
export function mallPreferential_addCart(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/addCart', method: 'POST', data })
}
// 删除购物车
export function mallPreferential_delCart(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/delCart', method: 'POST', data })
}
// 商品详情
export function mallPreferential_productDetail(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/productDetail?productId=' + data, method: 'GET' })
}
// 订单预览
export function mallPreferential_orderPreview(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/orderPreview', method: 'POST', data })
}

/**
 * @SPU 胡官韬
 * 2025-01-09 kadiam
*/
// 商品spu标签组列表
export function spuTagGroupList(data) {
  return bossCloud({ url: 'mall/api/mallProduct/spuTagGroupList', method: 'POST', data })
}
// 商品二级spu标签列表
export function secondLevelSpuTagList(data) {
  return bossCloud({ url: 'mall/api/mallProduct/secondLevelSpuTagList', method: 'POST', data })
}
// 根据spu标签id获取对应商品id
export function getProductIdBySpuTag(data) {
  return bossCloud({ url: 'mall/api/mallProduct/getProductIdBySpuTag', method: 'POST', data })
}
// 根据spu商品详情
export function spuProductDetail(data) {
  return bossCloud({ url: 'mall/api/mallProduct/spuProductDetail?productId=' + data, method: 'GET' })
}
// 根据spu标签id获取对应商品id
export function shoppingCartreplace(data) {
  return bossCloud({ url: 'mall/api/shoppingCart/replace', method: 'POST', data })
}
