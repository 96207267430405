<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-s-c" style="padding-left:0.3rem">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name flex-c-s-s" style="margin-left:0.3rem">
          <div class="order_del_status flex-r-s-c">
            <div v-if="orderInfo.orderCategory !== 3">
              {{ ['','待支付','已完成','已取消','待发货','已发货','售后中','已支付待确认'][orderInfo.handOverFlag] }}</div>
            <div v-else>{{ ['','待完成','已完成','已取消','待发货','已发货','售后中','已支付待确认'][orderInfo.handOverFlag] }}</div>
            <van-count-down v-if="orderInfo.handOverFlag === 1 && orderInfo.orderCategory !== 3" :time="orderInfo.time"
                            format="mm:ss" style="color:#fff;font-size:0.4rem;margin-left:0.1rem"
            />
          </div>
          <div class="order_del_tip" style="font-size: 0.32rem;">
            <span v-if="orderInfo.handOverFlag === 1 && orderInfo.orderCategory === 3">账期订单已提交，正在审核中~</span>
            <span v-if="orderInfo.handOverFlag === 1 && orderInfo.orderCategory !== 3">长时间不付款，订单将会取消哦~</span>
            <span v-if="orderInfo.handOverFlag === 2">您的订单已完成，感谢您的支持~</span>
            <span v-if="orderInfo.handOverFlag === 3">订单已取消，快去重新下单吧~</span>
            <span v-if="orderInfo.handOverFlag === 4">仓库正在装箱打包中~</span>
            <span v-if="orderInfo.handOverFlag === 5">货物已交付快递，收货请检查是否有破损~</span>
            <span v-if="orderInfo.handOverFlag === 6">请等待客服处理~</span>
            <span v-if="orderInfo.handOverFlag === 7">您的订单已支付，等待系统确认~</span>
          </div>
        </div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 地址选择 -->
    <div class="order_address_box flex-r-s-c" style="top:1.68rem;height:2.3rem;">
      <van-image width="0.69rem" height="0.69rem" src="https://oss.xiaoyi120.com/shop2.0/order/address_icon.png"
                 style="margin-left: 0.3rem;"
      />
      <div class="order_address_msg flex-c-s-s">
        <div class="address_msg_name">{{ orderInfo.address.name }} {{ orderInfo.address.tel }}
        </div>
        <div class="address_msg_addres van-multi-ellipsis--l2">
          地址：{{ orderInfo.address.address }}
        </div>
      </div>
      <!-- v-if="ischangeAddress" -->
      <div v-if="orderInfo.updateAddress" class="order_address_change flex-r-c-c" style="margin-left: 0.3rem;"
           @click="changeAddress(orderInfo.address.id)"
      >
        <span>修改</span>
        <van-icon name="arrow" />
      </div>
      <!-- <div v-else style="width:50rpx" /> -->
    </div>
    <div style="margin-top: -1.15rem;">
      <!-- 物流 -->
      <div v-if="orderInfo.logistics && packetList.length > 0" class="order_express_box flex-r-s-c">
        <van-image width="0.65rem" height="0.65rem" src="https://oss.xiaoyi120.com/shop2.0/order/express_icon.png"
                   style="margin-left: 0.3rem;"
        />
        <!-- <img style="width:0.6rem;height:0.6rem;margin-right:0.1rem;margin-left: 0.3rem;" src="./../../assets/express_icon.png"> -->
        <div class="order_address_msg2 flex-c-s-s" style="margin-left: 0.3rem;">
          <div class="address_msg_name">{{ packetList[0].expressName }}: {{ packetList[0].expressNo }}
          </div>
        </div>
        <div class="order_address_change2 flex-r-c-c" @click="action('express')">
          <span>查看物流</span>
          <van-icon name="arrow" color="rgb(42, 124, 181)" />
        </div>
      </div>
      <!-- 商品区域 -->
      <div class="order_pro_box">
        <div v-for="(company_item,company_index) in orderInfo.companies" :key="company_index" class="cart_company"
             style="margin-bottom:0;width:9.21rem !important;"
        >
          <div class="company_name flex-r-s-c" style="padding-left:0.1rem">
            <van-icon name="shop-o" />{{ company_item.companyName }}
          </div>
          <div class="company_pro_list">
            <div v-for="(pro_item,pro_index) in company_item.products" :key="pro_index" class="flex-r-s-c">
              <van-swipe-cell>
                <proCard04 :info="pro_item" :kind="2" />
                <!-- 优惠信息 -->
                <div v-if="pro_item.resActicityDtos" class="pro_discount_box">
                  <div class="pro_discount_items">
                    <div v-for="(discounts,discounts_index) in pro_item.resActicityDtos" :key="discounts_index"
                         class="pro_discount_item"
                    >
                      <span class="discounts_tag">{{ discounts.actionName }}：</span>
                      <span v-for="(discount,discount_index) in discounts.actions" :key="discount_index"
                            class="discounts_tag"
                      >
                        {{ discount }}
                      </span>
                    </div>
                  </div>
                </div>
              </van-swipe-cell>
            </div>
          </div>
          <van-cell-group>
            <van-field v-model="company_item.remark" label="备注:" placeholder="" readonly />
          </van-cell-group>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="order_check_box order_payway_box" style="min-height:5rem">
      <div class="check_title flex-r-s-c">
        <van-icon name="balance-list-o" color="#333333" size="0.5rem"
                  style="font-weight: bold;margin-top:0.07rem;margin-right:0.05rem"
        /> 订单信息
      </div>
      <div class="payway_types">
        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">商品总价</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem">￥{{ orderInfo.originalPrice }}</span>
          </div>
        </div>
        <template v-if="activeList&&activeList.length>0">
          <div v-for="(item_active,index_active) in activeList" :key="index_active"
               class="payway_type_item flex-r-sb-c"
          >
            <div class="type_item_left check_item_left  flex-r-s-c">
              <div class="check_item_name" style="padding-right: 0.1rem;">商品活动</div>
              <van-image v-if="item_active.actionType === 'MJ_AMOUNT_AC'" width="0.43rem" height="0.37rem"
                         src="https://oss.xiaoyi120.com/shop2.0/order/pay_minu.png"
              />
            </div>
            <div class="type_item_right  flex-r-s-c">
              <div style="margin-right:0.21rem;color:#ED0D0D;font-weight:bold;font-size: 0.32rem;">
                <!-- {{ item_active.name +"("+item_active.actionResult + ")" }}</div> -->
                -￥{{ item_active.actionResult }}
              </div>
            </div>
          </div>
        </template>

        <div v-if="orderInfo.usedCouponPrice" class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left flex-r-s-c">
            <div class="check_item_name" style="padding-right: 0.1rem;">优惠券</div>
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_coupon.png" />
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;font-size: 0.32rem;">-￥{{ orderInfo.usedCouponPrice }}
            </span>
          </div>
        </div>

        <div v-if=" orderInfo.vipDiscount < 100" class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left flex-r-s-c">

            <div class="check_item_name" style="padding-right: 0.1rem;">
              {{ orderInfo.mallVipLevelName }}享{{ orderInfo.vipDiscount }}折</div>
            <van-image width="0.43rem" height="0.37rem" src="https://oss.xiaoyi120.com/shop2.0/vip/vip_sub_icon.png" />
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;font-size: 0.32rem;">-￥{{ orderInfo.vipDiscountPrice }}
            </span>
          </div>
        </div>

        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">订单运费</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem">￥{{ orderInfo.expressPrice }}</span>
            <!-- <span style="margin-right:0.21rem">￥0.00</span> -->
          </div>
        </div>

        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">实付金额</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;color:#ED0D0D;font-size:0.48rem"><span
              style="color:#ED0D0D;font-size:0.37rem"
            >￥</span>{{ orderInfo.orderPrice }}</span>
          </div>
        </div>

        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">支付方式</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <van-icon v-if="orderInfo.orderCategory === 1" name="wechat-pay" color="rgb(36, 192, 37)" />
            <van-icon v-if="orderInfo.orderCategory === 3" name="balance-list" color="rgb(33, 123, 238)" />
            <span
              style="margin-right:0.21rem;font-weight:400"
            >{{ ['','在线支付','线下支付','账期结算'][orderInfo.orderCategory] }}</span>
          </div>
        </div>

        <template v-if="orderInfo.orderCategory === 3">
          <div class="payway_type_item flex-r-sb-c">
            <div class="type_item_left check_item_left  flex-r-s-c">
              <div class="check_item_name">选定分期</div>
            </div>
            <div class="type_item_right  flex-r-s-c">
              <span style="margin-right:0.21rem;font-weight:400">{{ orderInfo.quoteStagingResDto.month }}</span>
            </div>
          </div>
          <div class="payway_type_item flex-r-sb-c">
            <div class="type_item_left check_item_left  flex-r-s-c">
              <div class="check_item_name">利息金额</div>
            </div>
            <div class="type_item_right  flex-r-s-c">
              <span style="margin-right:0.21rem;font-weight:400">{{ orderInfo.quoteStagingResDto.interest }}</span>
            </div>
          </div>
          <div class="payway_type_item flex-r-sb-c">
            <div class="type_item_left check_item_left  flex-r-s-c">
              <div class="check_item_name">应还款总额</div>
            </div>
            <div class="type_item_right  flex-r-s-c">
              <span style="margin-right:0.21rem;font-weight:400">{{ orderInfo.quoteStagingResDto.payMoney }}</span>
            </div>
          </div>
          <div class="payway_type_item flex-r-sb-c">
            <div class="type_item_left check_item_left  flex-r-s-c">
              <div class="check_item_name">应还款方式</div>
            </div>
            <div class="type_item_right  flex-r-s-c">
              <span
                style="margin-right:0.21rem;font-weight:400"
              >{{ ['','逐月规则还款','差异还款','到期还款'][orderInfo.quoteStagingResDto.repayment] }}</span>
            </div>
          </div>
        </template>

        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">订单编号</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;font-weight:400">{{ orderInfo.orderNo }}</span>
          </div>
        </div>

        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">下单时间</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;font-weight:400">{{ orderInfo.addTime }}</span>
          </div>
        </div>

        <div class="payway_type_item flex-r-sb-c">
          <div class="type_item_left check_item_left  flex-r-s-c">
            <div class="check_item_name">需要货单</div>
          </div>
          <div class="type_item_right  flex-r-s-c">
            <span style="margin-right:0.21rem;font-weight:400">{{ orderInfo.followPdfPrint === 0 ? '否':'是' }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 售后列表 -->
    <div v-if="returnOrderList.length > 0" class="order_check_box order_payway_box"
         style="min-height:5rem;margin-top: -1.8rem;"
    >
      <div class="check_title flex-r-s-c">
        <van-icon name="after-sale" color="#333333" size="0.5rem"
                  style="font-weight: bold;margin-top:0.07rem;margin-right:0.05rem"
        /> 售后列表
      </div>
      <!-- list -->
      <div class="order_list_box">
        <div v-for="(returnOrder,return_index) in returnOrderList" :key="return_index">
          <div class="order_list_item" @click="$router.push('/order/returnStatus?refundId=' +returnOrder.refundId )">
            <!-- 订单单号和状态 -->
            <div class="item_title flex-r-sb-c">
              <div class="item_title_id">售后单号：{{ returnOrder.refundNo }}</div>
              <div class="item_title_status">
                {{ ['待处理','已通过','已拒绝','已完成','已取消'][returnOrder.status] }}</div>
            </div>
            <div class="flex-r-sb-c">
              <div v-if="returnOrder.productList.length === 1" class="item_left_pro flex-r-s-c">
                <div class="left_pro_img">
                  <van-image width="1.92rem" height="1.44rem" :src="returnOrder.productList[0].proIcon" />
                </div>
                <div class="left_pro_info">
                  <div class="pro_info_name van-multi-ellipsis--l2">{{ returnOrder.productList[0].proName }}</div>
                  <div class="pro_info_msg van-ellipsis">{{ returnOrder.productList[0].proBrand }} |
                    {{ returnOrder.productList[0].proModel }}</div>
                </div>
              </div>
              <div v-else class="item_left_pro">
                <div class="left_pro_img flex-r-c-c ">
                  <van-image v-for="(img,img_index) in returnOrder.productList" v-show="img_index<3" :key="img_index"
                             width="1.92rem" height="1.44rem" :src="img.proIcon"
                  />
                </div>
              </div>
              <!-- 订单价格和数量 -->
              <div class="item_right_info">
                <div class="right_info_money">￥{{ returnOrder.refundAmount }}</div>
                <div class="right_info_num">共计{{ returnOrder.productSumNumber }}件</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部bar -->
    <orderDel ref="orderSub" :info="orderInfo" @action="action" />
    <!-- 取消订单的popup -->
    <van-popup v-model="myshow">
      <div class="cancerOrder_box flex-c-c-c">
        <van-image width="2.59rem" height="2.93rem" src="https://oss.xiaoyi120.com/shop2.0/order/cancerOrder_pic.png" />
        <div class="cancerOrder_title">取消订单</div>
        <div class="cancerOrder_time">是否取消订单并将商品加回购物车？</div>
        <div class="cancerOrder_btn flex-r-sa-c">
          <div class="cancerOrder_btn_phone flex-r-c-c" @click="cancerOrder(0)">
            <!-- <van-icon name="phone" size="0.5rem" /><span>仅取消</span> -->
            <van-button plain round type="info" color="#0767ab">关闭订单</van-button>
          </div>
          <div class="cancerOrder_btn_online flex-r-c-c" @click="cancerOrder(1)">
            <van-button round type="info" color="#0767ab">重新加购</van-button>
            <!-- <van-icon name="comment" size="0.5rem" /><span>取消并加入</span> -->
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import './index.scss'
import './../cart/index.scss'
import orderDel from '@/components/bar/order_del'
import {
  mallOrder_buyer_detail, promotionActivity_usedByOrder, payStudy, cartAnotherOrder, mallOrder_express,
  resubmitOrderUnion, mallOrder_buyer_updateAdress, order_delete, refund_refundlist, updateComplete
} from '@/api/order'
import proCard04 from '@/components/pro_card_04'
export default {
  name: 'OrderDetail',
  components: { proCard04, orderDel },
  data() {
    return {
      orderId: '',
      orderInfo: { address: {} },
      ischangeAddress: false,
      activeList: [],
      myshow: false,
      // 商品列表
      orderSearch: { page: 1, limit: 10, orderId: '' },
      returnOrderList: [],
      listLoading: false,

      packetList: []
    }
  },
  created() {
    window.name = 'isReload'
    this.orderId = this.$route.query.id
    this.orderSearch.orderId = this.$route.query.id
    this.getDetail()
  },
  methods: {
    backPath() { window.history.back() },
    getDetail() {
      // 订单信息
      mallOrder_buyer_detail(this.orderId).then(res => {
        this.orderInfo = res.data
        // 倒计时
        if (res.data.handOverFlag === 1) {
          var startDate1 = new Date().getTime()
          var endDate1 = new Date(this.orderInfo.expireDate.replace(/-/g, '/')).getTime()
          this.$set(this.orderInfo, 'time', (endDate1 - startDate1))
        }
        // 地址的获取
        if (sessionStorage.getItem('address')) {
          this.orderInfo.address = JSON.parse(sessionStorage.getItem('address'))
          console.log('this.orderInfo.address', this.orderInfo.address)
          const info = {
            orderId: this.orderId,
            userReceiptAddressId: this.orderInfo.address.id
          }
          this.updateAddress(info)
        } else {
          const parms = {
            id: res.data.userReceiptAddressId,
            name: res.data.purchasingAgent,
            tel: res.data.purchasingAgentPhone,
            address: res.data.districtAddress + res.data.companyAddress
          }
          this.orderInfo.address = parms
        }
      })
      // 订单优惠
      promotionActivity_usedByOrder(this.orderId).then(res => {
        this.activeList = res.data
      })
      // 退货退款列表
      this.getReturnOrderList()

      // 获取物流列表
      mallOrder_express(this.orderId).then(res => { this.packetList = res.data })
    },
    // 更新地址
    updateAddress(info) {
      mallOrder_buyer_updateAdress(info).then(res => { })
      sessionStorage.removeItem('address')
    },
    // 加载退货订单
    getReturnOrderList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      refund_refundlist(this.orderSearch).then(res => {
        this.$toast.clear()
        this.returnOrderList = this.returnOrderList.concat(res.data.list)
      })
    },
    // 底部行为
    action(e) {
      console.log(e)
      if (e === 'pay') {
        this.$toast.loading({ message: '订单提交中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
        resubmitOrderUnion(this.orderId).then(res => {
          // 调起支付
          const info = { outOrder: res.data, orderId: this.orderId, type: 1 }
          payStudy(info)
        })
      }
      if (e === 'address') { this.ischangeAddress = !this.ischangeAddress }
      // if (e === 'new') { this.$router.push(`/order/subOrder?type=4&copyId=${this.orderId}`) }
      if (e === 'return') { this.$router.push(`/order/orderReturn?orderId=${this.orderId}`) }
      if (e === 'returnProcess') { this.$router.push(`/order/returnStatus?orderId=${this.orderId}`) }
      if (e === 'express') { this.$router.push(`/order/expressInfo?orderId=${this.orderId}`) }
      if (e === 'applyInvoice') {
        var orderPrice = (this.orderInfo.orderPrice * 1 - this.orderInfo.refundPrice * 1).toFixed(2)
        this.$router.push(`/invoice/applyInvoice?orderId=${this.orderId}&orderPrice=${orderPrice}&expressPrice=${this.orderInfo.expressPrice}&companyId=${this.orderInfo.companyId}`)
      }
      if (e === 'seeInvoice') { this.$router.push(`/invoice/detailInvoice?outOrder=${this.orderInfo.orderNo}`) }
      // if (e === 'cancerReturn') { 1 }
      if (e === 'del') { this.myshow = true }
      if (e === 'new') {
        cartAnotherOrder({ companyId: '1000', orderId: this.orderId }).then(res => {
          if (parseInt(res.data) === 0) {
            this.$toast({ message: '已加入购物车', duration: 2 * 1000 })
            this.$router.push(`/cart/cart`)
          } else {
            this.$toast({ message: '部分商品已下架', duration: 2 * 1000 })
            this.$router.push(`/cart/cart`)
          }
        })
      }
      if (e === 'complete') {
        updateComplete(this.orderId).then(res => {
          this.$toast({ message: '操作成功！', duration: 2 * 1000 })
          this.getDetail()
        })
      }
    },
    // 取消订单行为
    cancerOrder(type) {
      order_delete({ orderId: this.orderId, type: type }).then(res => {
        this.myshow = false
        this.$toast({ message: '已取消', duration: 2 * 1000 })
        // this.$router.replace('/order/returnOrderList')
        this.getDetail()
      })
    },
    // 修改地址
    changeAddress(addressId) {
      this.$router.push('/user/addressList?aId=' + addressId)
    }
  }
}
</script>

<style lang="scss">
.van-popup {
  border-radius: 0.27rem;
}
.cancerOrder_box {
  width: 8rem;
  height: 8.8rem;
  background: linear-gradient(180deg, #d4e9fa 0%, #ffffff 100%);
  opacity: 1;
  .cancerOrder_title {
    font-size: 0.48rem;
    font-weight: bold;
    line-height: 0.81rem;
    color: #333333;
    padding-top: 0.2rem;
  }
  .cancerOrder_time {
    font-size: 0.37rem;
    color: #999999;
    margin-bottom: 2rem;
  }
  .cancerOrder_btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.76rem;
    .cancerOrder_btn_phone {
      height: 0.59rem;
      font-size: 0.43rem;
      color: #333333;
    }
    .cancerOrder_btn_online {
      font-size: 0.43rem;
      color: #0767ab;
    }
    span {
      margin-left: 0.1rem;
    }
    .van-button {
      width: 2.93rem;
      text-align: center;
    }
  }
}
</style>

