<template>
  <div class="page_bg pay_result" style="overflow-y: hidden;background: rgb(255, 255, 255);">
    <div class="pay_result_card">
      <van-image v-if="status === 0 || status === 99" width="3.73rem" height="2.67rem"
                 src="https://oss.xiaoyi120.com/shop2.0/order/pay_success.png"
      />
      <van-image v-else width="3.73rem" height="2.67rem" src="https://oss.xiaoyi120.com/shop2.0/order/pay_failed.png" />
      <div v-if="status === 99" class="pay_status">提交成功</div>
      <div v-else class="pay_status">{{ status === 0 ?'支付成功':'支付失败' }}</div>
      <div v-if="status === 99" class="pay_tips">您的账期订单提交成功</div>
      <div v-else class="pay_tips">{{ status === 0 ?'客服稍后可能会与您联系':'支付遇到问题，请重新支付' }}</div>
      <div v-if="status === 0" class="pay_tips">请保持收货手机通畅</div>
      <template v-if="status === 0 || status === 99">
        <div class="flex-r-c-c">
          <van-button round plain type="info" color="#0767AB" style="margin-right:0.75rem"
                      @click="$router.push('/home/index')"
          >逛逛首页</van-button>
          <van-button round type="info" color="#0767AB"
                      @click="$router.push('/order/orderDetail?id=' + orderId + '&type=pay')"
          >查看订单</van-button>
        </div>
      </template>
      <template v-else-if="status === 2">
        <div class="flex-r-c-c">
          <van-button round plain type="info" color="#0767AB" style="margin-right:0.75rem"
                      @click="$router.push('/studyClass/studyIndex')"
          >逛逛首页</van-button>
          <!-- <van-button round type="info" color="#0767AB" @click="$router.push('/order/orderDetail?id=' + orderId + '&type=pay')">查看订单</van-button> -->
        </div>
      </template>
      <template v-else>
        <div class="flex-c-c-c">
          <van-button style="margin-bottom: 0.3rem;width: 5rem;" round type="info" color="#0767AB" @click="rePay">重新支付
          </van-button>
          <van-button style="margin-bottom: 0.3rem;width: 5rem;" round plain type="info" color="#0767AB"
                      @click="$router.push('/order/orderDetail?id=' + orderId + '&type=pay')"
          >查看订单</van-button>
          <van-button style="margin-bottom: 0.3rem;width: 5rem;" round plain type="info" color="#0767AB"
                      @click="$router.push('/product/classify')"
          >逛逛其他</van-button>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import './index.scss'
import { payStudy } from '@/api/order'
// import { payStudy, payStudy_class } from '@/api/order'
export default {
  name: 'PayResult',
  data() {
    return {
      outOrder: null,
      status: 0 // 0成功 1失败 2转账 3转账确认 4二次支付
    }
  },
  created() {
    this.$toast.clear()
    this.outOrder = this.$route.query.outOrder
    this.orderId = this.$route.query.orderId
    this.status = parseInt(this.$route.query.status)
    sessionStorage.removeItem('tabberJump')
  },
  methods: {
    backPath() { window.history.back() },
    rePay() {
      this.$toast.loading({ message: '支付发起中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      // 调起支付
      if (this.status === 1) { payStudy({ outOrder: this.outOrder }) }
      // if (this.status === 2) { payStudy_class({outOrder: this.outOrder }) }

      setTimeout(() => { this.$toast.clear() }, 2000)
    }
  }
}
</script>

<style>
body {
  background: #f7f7f7;
}
</style>
