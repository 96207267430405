/**
  *@页面的spu弹出选择
  *2025-01-09 kadiam
*/
<template>
  <van-action-sheet v-model="maskShow" @closed="closed">
    <div class="spu_mask_content flex-r-s-c">
      <div class="close_icon" @click="maskShow = false">
        <van-icon name="clear" />
      </div>
      <van-image v-if="source === 'pro'" width="3.41rem" height="2.56rem" :src="myInfo.proPrcUrls[0] " />
      <van-image v-if="source === 'cart'" width="3.41rem" height="2.56rem" :src="myInfo.proIconUrl || myInfo.iconUrl" />
      <div class="mask_pro_info flex-c-s-s">
        <div class="pro_price">
          <template v-if="myInfo.freshmanActivityProduct">
            ￥<span> {{ myInfo.freshmanActivityProduct.price }} </span>
          </template>
          <template v-else>
            ￥<span> {{ myInfo.proPrice }} </span>
          </template>
        </div>
        <div class="pro_name van-multi-ellipsis--l2">
          <van-image v-if="myInfo.releaseFlag === 3" width="0.75rem" height="0.43rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/presell_tag.png"
          />
          {{ myInfo.proName }}
        </div>
        <div class="pro_other van-multi-ellipsis--l2">{{ myInfo.proBrand }} | {{ myInfo.proModel }}
        </div>
      </div>
    </div>
    <div class="spu_pro_spu">
      <div class="name">{{ level01Name }}</div>
      <div class="spu_item flex-r-s-c">
        <div v-for="(level01,level01_index) in level01List" :key="level01_index"
             :class="choosed01 === level01_index ? 'name_actived name':'name'"
             @click="change01level(level01,level01_index)"
        >{{ level01.spuTagName }}
        </div>
      </div>
    </div>
    <div class="spu_pro_spu">
      <div class="name">{{ level02Name }}</div>
      <div class="spu_item flex-r-s-c">
        <div v-for="(level02,level02_index) in level02List" :key="level02_index"
             :class="choosed02 === level02_index ? 'name_actived name':'name'"
             @click="change02level(level02,level02_index)"
        >{{ level02.spuTagName }}
        </div>
      </div>
    </div>
    <div class="spu_pro_num flex-r-sb-c">
      <div class="name">采购数量</div>
      <van-stepper v-model="value" :min="min" :max="max" :step="step" integer disable-input theme="round"
                   button-size="22" @change="onChange"
      />
    </div>
    <div class="spu_pro_num flex-r-sb-c">
      <div class="name">总计</div>
      <div class="total_price">￥ <span style="font-size: 0.53rem;">{{ totalProPrice }}</span></div>
    </div>
    <van-divider />
    <div class="spu_mask_pro_btn flex-r-c-c">
      <div v-if="source === 'pro'" class="right_btn flex-r-s-s">
        <div class="right_btn_cart" @click="addCart">加入购物车</div>
        <div class="right_btn_pay" @click="subPro">立即采购</div>
      </div>
      <div v-if="source === 'cart'" class="right_btn flex-r-s-s">
        <div class="right_btn_confirm" @click="comfirmPro">确认</div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import './index.scss'
import { spuTagGroupList, secondLevelSpuTagList, getProductIdBySpuTag, spuProductDetail, shoppingCartreplace } from '@/api/pro'
import { shoppingCart_addUnion } from '@/api/cart'
export default {
  name: 'SpuDrawer',
  props: {
    source: { type: String, default: '' },
    proId: { type: String, default: '' },
    spuLevel01Id: { type: String, default: '' },
    spuLevel02Id: { type: String, default: '' },
    info: { type: Object, default: null }
  },
  data() {
    return {
      myInfo: this.info,
      maskShow: false,
      level01Id: '',
      level02Id: '',
      level01Name: '',
      level02Name: '',
      level01List: [],
      level02List: [],
      choosed01: 0,
      choosed02: 0,

      value: '',
      min: 1,
      max: 9999,
      step: 1,
      totalProPrice: '',

      newProductId: null

    }
  },
  created() {
    this.level01Id = this.spuLevel01Id
    this.level02Id = this.spuLevel02Id
  },
  methods: {
    changeShow(e) {
      this.value = this.source === 'pro' ? this.myInfo.minQuantity : this.myInfo.proNumber
      this.maskShow = e
      this.min = this.myInfo.minQuantity
      this.step = this.myInfo.multiplyQuantity === 0 ? 1 : this.myInfo.minQuantity
      this.totalProPrice = (parseInt(this.value) * this.myInfo.proPrice * 1).toFixed(2)
      this.loadSpuLevelList()
    },
    updatePro(info) {
      this.spuProductList = info.spuProductList
      this.$set(this, 'myInfo', info)

      this.min = info.minQuantity
      this.step = info.multiplyQuantity === 0 ? 1 : info.minQuantity
      this.value = info.minQuantity
      this.totalProPrice = (parseInt(this.value) * info.proPrice * 1).toFixed(2)
    },
    loadSpuLevelList() {
      spuTagGroupList({ productId: this.proId }).then(res => {
        this.level01Name = res.data[0].spuTagGroupName
        this.level02Name = res.data.length === 2 ? res.data[1].spuTagGroupName : ''
        this.level01List = res.data[0].spuTagList
        this.level02List = res.data.length === 2 ? res.data[1].spuTagList : []
        this.$nextTick(() => {
          this.loadActived(res.data[0].spuTagList, 1)
          this.loadActived(res.data[1].spuTagList, 2)
        })
      })
    },
    loadActived(list, level) {
      console.log('loadActived 1', this.spuLevel02Id)
      console.log('loadActived 2', list, level)
      var spuid = ''
      if (this.source === 'pro') {
        spuid = level === 1 ? this.myInfo.spuProductList[0].firstLevelTagId : this.myInfo.spuProductList[0].secondLevelTagId
      }
      if (this.source === 'cart') {
        spuid = level === 1 ? this.spuLevel01Id : this.spuLevel02Id
      }
      console.log('getspuId', spuid)
      var spuindex = 0
      if (list.length > 0) {
        for (const i in list) {
          if (list[i].spuTagId === spuid) {
            spuindex = parseInt(i); break
          }
        }
      }
      if (level === 1) this.choosed01 = spuindex
      if (level === 2) this.choosed02 = spuindex
    },
    change01level(item, index) {
      this.level01Id = item.spuTagId
      this.choosed01 = index
      secondLevelSpuTagList({ firstLevelTagId: item.spuTagId }).then(res => {
        if (res.data.length === 0) {
          getProductIdBySpuTag({ firstLevelTagId: this.level01Id, secondLevelTagId: null }).then(resx => {
            if (this.source === 'pro') {
              this.$emit('loadPro', resx.data)
            }
            if (this.source === 'cart') {
              this.newProductId = resx.data
              spuProductDetail(resx.data).then(ress => {
                this.spuProductList = ress.data.spuProductList
                this.$set(this, 'myInfo', ress.data)

                this.min = ress.data.minQuantity
                this.step = ress.data.multiplyQuantity === 0 ? 1 : ress.data.minQuantity
                this.value = ress.data.minQuantity
                this.totalProPrice = (parseInt(ress.data.minQuantity) * ress.data.proPrice * 1).toFixed(2)
              })
            }
          })
        } else {
          this.level02List = res.data
          this.choosed02 = null
        }
      })
    },
    change02level(item, index) {
      this.level02Id = item.spuTagId
      this.choosed02 = index
      getProductIdBySpuTag({ firstLevelTagId: this.level01Id, secondLevelTagId: item.spuTagId }).then(res => {
        console.log('spu change02level', res.data)
        if (this.source === 'pro') {
          this.$emit('loadPro', res.data)
        }
        if (this.source === 'cart') {
          this.newProductId = res.data
          spuProductDetail(res.data).then(res => {
            this.spuProductList = res.data.spuProductList
            this.$set(this, 'myInfo', res.data)

            this.min = res.data.minQuantity
            this.step = res.data.multiplyQuantity === 0 ? 1 : res.data.minQuantity
            this.value = res.data.minQuantity
            this.totalProPrice = (parseInt(res.data.minQuantity) * res.data.proPrice * 1).toFixed(2)
          })
        }
      })
    },

    onChange(value) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.value = value
        this.totalProPrice = (parseInt(value) * this.myInfo.proPrice * 1).toFixed(2)
      }, 500)
    },
    addCart() {
      const parms = { productId: this.proId, proNumber: this.value }
      console.log(parms)
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
        this.maskShow = false
      })
    },
    subPro() {
      let url = ''
      url = `/order/subOrder?item=${this.proId}-${this.value}-1000&type=2`
      this.$router.push(url)
    },
    comfirmPro() {
      console.log('value 3', this.value)
      shoppingCartreplace({ oldProductId: this.proId, newProductId: this.newProductId || this.proId, proNumber: this.value }).then(res => {
        this.$emit('loadPro', res.data)
      })
    },
    closed() {
      this.$emit('closed')
    }
  }
}
</script>
