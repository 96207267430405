<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div>
      <div class="back_top_name flex-r-sb-c" style=" padding:0 0.32rem;background: #ffffff;font-size: 0.48rem;">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name top_ser">
          <van-search v-model="orderSearch.key" class="classify_ser" placeholder="请输入搜索关键词" shape="round"
                      @search="serOrder"
          />
        </div>
        <div class="top_icon" />
      </div>
      <div v-if="orderSearch.status !== '6'" class="order_list_top">
        <van-row>
          <van-col :class="orderSearch.status === '' ?'top_van-col_actived top_van-col':'top_van-col' " :span="4"
                   @click="topChange('')"
          >全部
          </van-col>
          <van-col :class="orderSearch.status === '1' ?'top_van-col_actived top_van-col':'top_van-col' " :span="5"
                   @click="topChange('1')"
          >待付款
          </van-col>
          <van-col :class="orderSearch.status === '4'?'top_van-col_actived top_van-col':'top_van-col' " :span="5"
                   @click="topChange('4')"
          >待发/预售
          </van-col>
          <van-col :class="orderSearch.status === '5'?'top_van-col_actived top_van-col':'top_van-col' " :span="5"
                   @click="topChange('5')"
          >待收货
          </van-col>
          <van-col :class="orderSearch.status === '2'?'top_van-col_actived top_van-col':'top_van-col' " :span="5"
                   @click="topChange('2')"
          >已完成
          </van-col>
        </van-row>
      </div>
    </div>
    <!-- list -->
    <div class="order_list_box" name="list">
      <MyscrollList ref="myList01" :loading="listLoading" :list="orderList" :line-type="1" @myload="orderload">
        <template #default="slotProps">
          <div class="order_list_item" @click="$router.push('/order/orderDetail?id=' +slotProps.item.orderId )">
            <!-- 订单单号和状态 -->
            <div class="item_title flex-r-sb-c">
              <div class="item_title_id">订单号：{{ slotProps.item.orderNo }}</div>
              <div v-if="slotProps.item.handOverFlag === 1 && slotProps.item.orderCategory !== 3"
                   class="item_title_status count_down flex-r-c-c"
              >
                <div class="status_left">待付款</div>
                <div class="status_right">
                  <van-count-down :time="slotProps.item.time" format="mm:ss"
                                  style="color:#0767AB;font-size:0.32rem;margin-top: 2px"
                  />
                </div>
              </div>
              <div v-else class="item_title_status">
                <span v-if="slotProps.item.handOverFlag === 2">
                  <span
                    v-if="(slotProps.item.orderPrice-slotProps.item.expressPrice*1) === slotProps.item.refundPrice*1"
                  >已退款</span>
                  <span v-if="slotProps.item.refundPrice*1 === 0">已完成</span>
                  <span
                    v-else-if="(slotProps.item.orderPrice-slotProps.item.expressPrice*1) > slotProps.item.refundPrice*1 "
                  >已完成（有退款）</span>
                </span>
                <span v-else>{{ ['','待完成','已完成','已取消','待发货','已发货','售后中','已支付待确认'][slotProps.item.handOverFlag] }}</span>
              </div>
            </div>
            <div class="flex-r-sb-c">
              <div v-if="slotProps.item.details.length === 1" class="item_left_pro flex-r-s-c">
                <div class="left_pro_img">
                  <van-image width="2rem" height="100%" :src="slotProps.item.details[0].proIcon" />
                </div>
                <div class="left_pro_info">
                  <div class="pro_info_name van-multi-ellipsis--l2">{{ slotProps.item.details[0].proName }}</div>
                  <div class="pro_info_msg van-ellipsis" style="width:4rem">{{ slotProps.item.details[0].proBrand }} |
                    {{ slotProps.item.details[0].proModel }}</div>
                </div>
              </div>
              <div v-else class="item_left_pro">
                <div class="left_pro_img flex-r-c-c ">
                  <van-image v-for="(img,img_index) in slotProps.item.details" v-show="img_index<3" :key="img_index"
                             width="2rem" height="100%" :src="img.proIcon"
                  />
                </div>
              </div>
              <!-- 订单价格和数量 -->
              <div class="item_right_info">
                <div class="right_info_money">￥{{ slotProps.item.originalPrice }}</div>
                <div class="right_info_num">共计{{ slotProps.item.totalProducts }}件</div>
              </div>
            </div>
            <div class="order_info_bottom flex-r-sb-c">
              <div v-if="slotProps.item.discount !== '0.00'" class="bottom_coupon">优惠 ￥{{ slotProps.item.discount }}
              </div>
              <div v-else />
              <div class="bottom_price">实付 ￥ <span style="font-size:0.4rem;">{{ slotProps.item.orderPrice }}</span>
              </div>
            </div>
          </div>
        </template>
      </MyscrollList>
    </div>
  </div>
</template>

<script>
import './index.scss'
import MyscrollList from '@/components/MyscrollList'
import { mallOrder_buyer_list } from '@/api/order'
export default {
  name: 'OrderList',
  components: { MyscrollList },
  data() {
    return {
      serValue: '',
      // 商品列表
      orderSearch: { page: 1, limit: 10, status: '' },
      orderList: [],
      listLoading: false
    }
  },
  created() {
    console.log('created')
    var flag = parseInt(sessionStorage.getItem('ordeListStatus')) === 0
    if (!flag) {
      this.orderSearch.status = sessionStorage.getItem('ordeListStatus')
      this.$forceUpdate()
      this.getOrderList()
      // sessionStorage.removeItem('ordeListStatus')
    } else {
      this.orderSearch.status = ''
      this.$forceUpdate()
      this.getOrderList()
    }
  },
  activated() {
    console.log('000001', this.orderSearch.status)
    var ordeListStatus = parseInt(sessionStorage.getItem('ordeListStatus')) === 0 ? '' : sessionStorage.getItem('ordeListStatus')
    console.log('000002', ordeListStatus)
    if (this.orderSearch.status === ordeListStatus) {
      console.log('xiangtong', this.orderSearch.status)
    } else {
      this.orderSearch.status = ordeListStatus
      this.$refs.myList01.load_status_clear()
      this.orderSearch.page = 1
      this.orderList = []
      this.$forceUpdate()
      this.getOrderList()
    }
    // if (sessionStorage.getItem('ordeListStatus')) {
    //   this.$refs.myList01.load_status_clear()
    //   this.orderSearch.page = 1
    //   this.orderList = []
    //   if (parseInt(sessionStorage.getItem('ordeListStatus')) === 0) {
    //     this.orderSearch.status = ''
    //   } else {
    //     this.orderSearch.status = sessionStorage.getItem('ordeListStatus')
    //   }
    //   this.$forceUpdate()
    //   this.getOrderList()
    //   sessionStorage.removeItem('ordeListStatus')
    // }
  },
  methods: {
    backPath() { window.history.back() },
    getOrderList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      mallOrder_buyer_list(this.orderSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.orderList = this.orderList.concat(res.data.list)
        for (const i in this.orderList) {
          if (this.orderList[i].expireDate) {
            var startDate1 = new Date().getTime()
            var endDate1 = new Date(this.orderList[i].expireDate.replace(/-/g, '/')).getTime()
            this.$set(this.orderList[i], 'time', (endDate1 - startDate1))
          }
        }
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    orderload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.orderSearch.page = this.orderSearch.page + 1
        this.getOrderList()
        // 加载状态结束
      }, 50)()
    },
    // 搜索订单
    serOrder(e) {
      this.$refs.myList01.load_status_clear()
      this.orderSearch.key = e
      this.orderSearch.page = 1
      this.orderList = []
      this.getOrderList()
    },
    topChange(index) {
      this.$refs.myList01.load_status_clear()
      this.orderSearch.status = index
      this.orderSearch.page = 1
      this.orderList = []
      this.getOrderList()
    }
  }
}
</script>
<style lang="scss">
.top_ser {
  .classify_ser {
    .van-search__content {
      width: 7rem;
      height: 0.85rem;
      background: #ffffff;
      border: 1px solid #0767ab;
      opacity: 1;
      border-radius: 0.48rem;
    }
  }
}
.order_list_top {
  background: #ffffff;
  text-align: center;
  .top_van-col {
    height: 1rem;
    font-size: 0.37rem;
    color: #333333;
  }
  .top_van-col_actived {
    font-weight: bold;
    color: #0767ab;
  }
}
</style>
