<template>
  <div class="page_bg">
    <van-skeleton title :row="23" :loading="loading">
      <div v-if="reload" style="margin-bottom: 3rem;">
        <template v-if="cartProInfo.cartCompanies.length > 0 ">
          <div v-for="(company_item,company_index) in cartProInfo.cartCompanies" :key="company_index"
               class="cart_company" style="border-radius: 0.27rem;margin-top: 0.27rem;"
          >
            <div class="company_name flex-r-s-c">
              <van-icon name="shop-o" />{{ company_item.companyName }}
            </div>
            <div class="company_pro_list">
              <van-checkbox-group ref="cartCheck" v-model="company_item.result" class="checkboxGroup">
                <div v-for="(pro_item,pro_index) in company_item.cartProducts" :key="pro_index" class="flex-c-s-c"
                     style="margin-top:0.1rem"
                >
                  <div v-for="(activity,activity_index) in pro_item.activityViewList" :key="activity_index"
                       class="cart_actives flex-r-s-c"
                  >
                    <van-image v-if="activity.activityViewType === 2" width="0.69rem" height="0.37rem"
                               src="https://oss.xiaoyi120.com/shop2.0/cart/cart_minu.png"
                    />
                    <van-image v-if="activity.activityViewType === 3" width="0.69rem" height="0.37rem"
                               src="https://oss.xiaoyi120.com/shop2.0/cart/cart_add.png"
                    />
                    <van-image v-if="activity.activityViewType === 5" width="0.69rem" height="0.37rem"
                               src="https://oss.xiaoyi120.com/shop2.0/cart/cart_return.png"
                    />
                    <div v-if="activity.activityViewType < 6 && activity.activityViewType > 1"
                         class="cart_actives_name van-ellipsis" style="margin-left:0.1rem"
                    >
                      {{ activity.activityViewName }}
                    </div>
                  </div>
                  <div class="flex-r-s-c">
                    <van-checkbox v-if="pro_item.releaseFlag !== 2" v-model="pro_item.checked" :name="pro_index"
                                  checked-color="#0767AB" style="padding-right:0.2rem"
                                  @click="itemChange($event,pro_item)"
                    />
                    <van-swipe-cell>
                      <proCard02 v-if="reloadPro" :ref="'proCard'+pro_index" :info="pro_item" @changeNum="changeNum"
                                 @openCart="openCart($event,pro_index)"
                      />
                      <template #right>
                        <van-button color="#D1531C" square type="danger" class="delete-button"
                                    @click="delPro(company_index,pro_item,pro_index)"
                        >
                          <van-icon name="delete-o" />
                          <div>删除</div>
                        </van-button>
                      </template>
                    </van-swipe-cell>
                  </div>
                </div>
              </van-checkbox-group>
            </div>
          </div>
        </template>
        <div v-else class="cart_empty">
          <van-image width="4.8rem" height="3.2rem" src="https://oss.xiaoyi120.com/shop2.0/cart/cart_empty.png" />
          <div class="cart_empty_tips">购物车里空空如也</div>
          <van-button round type="info" color="rgb(7, 103, 171)" style="width: 5.33rem;"
                      @click="$router.push(`/home/index`)"
          >去逛逛</van-button>
        </div>
      </div>
      <!-- 顺手带一件 -->
      <!-- <div class="cart_recommend flex-c-s-s">
        <div class="cart_recommend_top flex-r-sb-s">
          <div class="flex-r-s-s">
            <van-image width="0.53rem" height="0.53rem"
                       src="https://oss.xiaoyi120.com/shop2.0/cart/cart_recommend.png"
            />
            <span>随手购</span>
          </div>
          <div class="cart_recommend_top_change flex-r-c-c" style="margin-left:5.3rem" @click="changeOne">
            <van-icon name="replay" />
            <span>换一组</span>
          </div>
        </div>
        <div class="cart_buOne_pro" name="list">
          <div v-for="(item_by,index_by) in proList" :key="index_by" class="x">
            <div class="cart_buOne_pro_box flex-r-s-s">
              <div class="img">
                <van-image width="2.56rem" height="2.56rem" :src="item_by.saleIconUrl" />
              </div>
              <div class="msg flex-c-s-s">
                <div class="msg_name van-multi-ellipsis--l2">{{ item_by.saleName }}</div>
                <div class="msg_brand_model van-ellipsis">{{ item_by.proBrand }} | {{ item_by.proModel }}</div>
                <div class="money_cart flex-r-sb-c">
                  <div class="money">
                    <span style="font-size:0.29rem">￥</span>{{ item_by.salePrice }}
                  </div>
                  <div class="cart">
                    <van-icon name="shopping-cart" size="0.5rem" color="rgb(7, 103, 171)"
                              @click.stop="toCart(item_by)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <br>
      <br>
      <!-- 提交栏 -->
      <div v-if="cartProInfo.cartCompanies.length > 0" class="cart_sub">
        <transition :name="showProp ?'van-slide-up' : 'van-slide-up'">
          <div v-show="showProp">
            <div class="cart_sub_mode" />
            <div class="cart_sub_prop">
              <div class="cart_sub_prop_top flex-r-sa-c">
                <div />
                <div />
                <div class="top_name">优惠明细</div>
                <div />
                <div class="top_btn">
                  <van-icon name="clear" @click="showProp = false" />
                </div>
              </div>
              <div class="cart_sub_prop_body">
                <van-cell-group inset>
                  <van-cell title="商品总额" style="color:#000000">
                    <template #default> <span class="cell_custom-title">{{ '￥'+cartProInfo.productTotalPrice }}</span>
                    </template>
                  </van-cell>
                  <!-- <van-cell title="顺丰运费" style="color:#000000">
                    <template #default> <span class="cell_custom-title">￥20.00</span> </template>
                  </van-cell>
                  <van-cell v-if="cartProInfo.activityPriceList" title="活动满减" style="color:#000000">
                    <template #default> <span
                      class="cell_custom-title"
                    >{{ '-￥'+cartProInfo.activityPriceList[0] }}</span>
                    </template>
                  </van-cell> -->
                  <!-- <van-cell title="活动返券" style="color:#000000">
                  <template #default> <span class="cell_custom-title">{{ '-￥'+cartProInfo.productTotalPrice }}</span>
                  </template>
                </van-cell> -->
                  <!-- <van-cell title="运费限免" style="color:#000000">
                    <template #default> <span class="cell_custom-title">-￥20.00</span> </template>
                  </van-cell> -->
                  <van-cell title="总计优惠" label="以上优惠不含礼品价格及优惠券，用券金额请在结算页查看">
                    <template #title>
                      <span class="custom-title" style="color:#ED0D0D">总计优惠</span>
                    </template>
                    <template #default>
                      <span class="custom-title" style="color:#ED0D0D">{{ '￥'+cartProInfo.reducedTotalPrice }}</span>
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
            </div>
          </div>
        </transition>
        <div class="cart_sub_menu  flex-r-sb-c">
          <div />
          <div class="cart_sub_check">
            <van-checkbox v-model="checkedAll" checked-color="#0767AB" @click="checkAll">全选</van-checkbox>
          </div>
          <div class="cart_sub_msg flex-c-s-s">
            <div v-if="cartProInfo.calcTotalPrice" class="cart_sub_money">实付金额：
              <span
                style="font-size:0.48rem;color:#ED0D0D"
              >￥{{ $utils.moneyDote(cartProInfo.calcTotalPrice)[0] }}</span>
              <span style="color:#ED0D0D">.{{ $utils.moneyDote(cartProInfo.calcTotalPrice)[1] || '00' }}</span>
            </div>
            <div v-if="cartProInfo.reducedTotalPrice" class="cart_sub_coupon" style="color:#999999">
              优惠￥{{ cartProInfo.reducedTotalPrice }}
              <span style="font-size:0.32rem;color:#ED0D0D" @click="showProp = true"> 看明细</span>
            </div>
          </div>
          <div class="cart_sub_btn">
            <van-button round type="info" color="#0767AB" @click="onSubmit"> 去结算{{ ' ( '+ result +' ) ' }} </van-button>
          </div>
          <div />
        </div>
      </div>
    </van-skeleton>
    <!-- spu -->
    <spuDrawer v-if="showSpu" ref="spuDrawer" :info="proDetailInfo" :pro-id="pid" source="cart"
               :spu-level01-id="spuProductList.length > 0 ? spuProductList[0].firstLevelTagId : null"
               :spu-level02-id="spuProductList.length > 0 ? spuProductList[1].secondLevelTagId : null"
               @loadPro="loadPro" @closed="closed"
    />
    <!-- 直播引导 -->
    <div v-if="showLive" class="navtive_video flex-c-c-c">
      <van-image v-if="liveList[0]" width="2.8rem" height="3.3rem" :src="liveList[0].imageUrl" @click="jumpLive" />
      <!-- <van-icon v-if="liveList[0]" name="clear" size="0.6rem" style="opacity: 0.7;" @click="closeLive" /> -->
    </div>
    <!-- Tabber -->
    <Tabber ref="tabber" :index="3" />
  </div>
</template>

<script>
import './index.scss'
import Tabber from '@/components/tabber'
import proCard02 from '@/components/pro_card_02'
import {
  shoppingCart_listUnion, shoppingCart_check, shoppingCart_delete,
  shoppingCart_totalPrice, shoppingCart_checkV2All, shoppingCart_addUnion
} from '@/api/cart'
import { mallSaleModule_imageList, productModule_productList } from '@/api/home'
import spuDrawer from '@/components/spuDrawer'
export default {
  name: 'Cart',
  components: { Tabber, proCard02, spuDrawer },
  data() {
    return {
      loading: false,
      reload: true,
      cartProInfo: { cartCompanies: [], reducedTotalPrice: 0, calcTotalPrice: 0, productTotalPrice: 0 },
      reloadPro: false,
      activityPriceList: [],
      result: [],
      checkedAll: false,
      // 商品列表 顺手带一件
      proSearch: { page: 1, limit: 2, mallProductModuleId: '1782335384953503744' },
      proList: [],
      showProp: false,
      releaseNum: 0,
      listLength: 0,
      // 直播引导
      liveList: [],
      showLive: false,
      // SPU
      showSpu: false,
      proDetailInfo: {},
      pid: '',
      proindex: '',
      spuProductList: []
    }
  },
  created() {
    this.getCartList()
    this.$utils.cartNum(this.$store)
    this.getProList()
  },
  activated() {
    // this.getCartList()
    console.log('keepalive 进入cart')
    if (sessionStorage.getItem('tabberJump')) {
      // this.$router.go(0)
      window.location.reload()
      sessionStorage.removeItem('tabberJump')
      this.$utils.cartNum(this.$store)
      this.getProList()
    }
  },
  deactivated() {
    console.log('keepalive 离开cart')
  },
  methods: {
    // 获取总价
    getTotalPrice() {
      shoppingCart_totalPrice().then(res => {
        this.cartProInfo.reducedTotalPrice = res.data.reducedTotalPrice
        this.cartProInfo.calcTotalPrice = res.data.calcTotalPrice
        this.cartProInfo.productTotalPrice = res.data.productTotalPrice
        this.activityPriceList = res.data.activityPriceList
      })
    },
    getCartList() {
      // this.showLive = parseInt(sessionStorage.getItem('liveFlag_cart')) === 1
      this.showLive = parseInt(sessionStorage.getItem('liveFlag')) === 1
      this.releaseNum = 0; this.result = 0
      // this.reloadPro = false
      shoppingCart_listUnion().then(res => {
        this.loadItem()
        this.cartProInfo.cartCompanies = res.data.cartCompanies
        for (var i = 0; i < this.cartProInfo.cartCompanies.length; i++) {
          this.$set(this.cartProInfo.cartCompanies[i], 'result', [])
          for (var j = 0; j < this.cartProInfo.cartCompanies[i].cartProducts.length; j++) {
            if (this.cartProInfo.cartCompanies[i].cartProducts[j].checked === 1 && this.cartProInfo.cartCompanies[i].cartProducts[j].releaseFlag !== 2) {
              this.cartProInfo.cartCompanies[i].result.push(j)
              this.$forceUpdate()
            }
            // 获取失效个数
            if (this.cartProInfo.cartCompanies[i].cartProducts[j].releaseFlag === 2) {
              this.cartProInfo.cartCompanies[i].result.push(j)
              this.releaseNum++
              console.log('this.releaseNum', this.releaseNum)
            }
          }
          this.result = this.cartProInfo.cartCompanies[i].result.length - this.releaseNum
          if (this.result < 0) { this.result = 0 }
        }
        this.listLength = this.cartProInfo.cartCompanies[0].cartProducts.length - this.releaseNum
        console.log('listLength', this.listLength)
        console.log('this.result', this.result)
        if (this.result === 0 && this.listLength === 0) { this.checkedAll = false } else {
          if (this.result === this.listLength) { this.checkedAll = true } else { this.checkedAll = false }
        }
        this.getTotalPrice()
        this.reload = true
        // this.loading = false
        this.reloadPro = true
      })
    },
    // 单个商品选择
    itemChange(val, item) {
      item.checked = item.checked === 1 ? 0 : 1
      const parms = { checked: item.checked, shoppingCartId: item.shoppingCartId }
      shoppingCart_check(parms).then(res => {
        this.getTotalPrice()
        this.getCartList()
      })
      // 计算个数
      // if (item.checked === 0) { this.result = this.result - 1 }
      // if (item.checked === 1) { this.result = this.result + 1 }
      // if (this.result === 0 && this.listLength === 0) { this.checkedAll = false } else {
      //   if (this.result === this.listLength) { this.checkedAll = true } else { this.checkedAll = false }
      // }
    },
    // 购物车全选
    checkAll() {
      shoppingCart_checkV2All({ checked: this.checkedAll ? 1 : 0 }).then(res => {
        this.result = this.checkedAll ? this.cartProInfo.cartCompanies[0].cartProducts.length - this.releaseNum : 0
        this.$refs.cartCheck[0].toggleAll(this.checkedAll)
        this.getTotalPrice()
        this.getCartList()
      })
    },
    // 修改数量
    changeNum(num) { this.getTotalPrice() },
    // 删除商品
    delPro(up_index, row, row_index) {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      this.reload = false
      shoppingCart_delete(row.shoppingCartId).then(res => {
        this.$toast.clear()
        this.cartProInfo.cartCompanies[up_index].cartProducts.splice(row_index, 1)
        this.getCartList()
        this.$forceUpdate()
        this.$nextTick(() => {
          this.reload = true
        })
      })
    },
    onSubmit() {
      console.log(this.result)
      // 组装数据 --》
      var list = this.cartProInfo.cartCompanies
      var selected = []
      for (var i = 0; i < list.length; i++) {
        for (var j = 0; j < list[i].result.length; j++) {
          selected.push(list[i].cartProducts[list[i].result[j]].shoppingCartId + '-' + list[i].cartProducts[list[i].result[j]].proNumber)
        }
      }

      if (selected.length === 0) {
        this.$toast({ message: '暂无商品被选中, 无法提交', duration: 3 * 1000 })
      } else {
        const items = selected.join(',')
        this.$router.push(`/order/subOrder?item=${items}&type=1`)
      }
    },
    // 顺手带一件
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      productModule_productList(this.proSearch).then(res => {
        this.$toast.clear()
        if (res.data.list.length > 0) {
          this.proList = res.data.list
        } else {
          this.proSearch.page = 1
          this.getProList()
        }
      })
    },
    changeOne() {
      this.proSearch.page = this.proSearch.page * 1 + 1
      this.getProList()
    },
    // 加入购物车
    toCart(item) {
      const parms = { productId: item.productId, proNumber: item.minQuantity || 1 }
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.updataCart()
      })
    },
    // 加入购物车 更新 当前视图
    updataCart() {
      this.reload = false
      this.getCartList()
    },
    // 加载营销模块- 直播提示
    loadItem() {
      mallSaleModule_imageList({ pageNum: 1, pageSize: 9999, mallSaleModuleId: '1686919882362458112' }).then(res => {
        this.liveList = res.data.data
      })
    },
    jumpLive() { this.$router.push(`/product/liveList`); sessionStorage.setItem('listJump', 0) }, // 跳转直播
    closeLive() { this.showLive = false; sessionStorage.setItem('liveFlag', 2) },

    openCart(info, index) {
      this.proDetailInfo = info
      this.pid = info.productId
      this.proindex = index
      this.spuProductList = [
        { firstLevelTagName: info.firstLevelTagName, firstLevelTagId: info.firstLevelTagId },
        { secondLevelTagName: info.secondLevelTagName, secondLevelTagId: info.secondLevelTagId }
      ]
      this.showSpu = true
      this.$nextTick(() => {
        console.log('openCart', this.spuProductList)
        this.$refs.spuDrawer.changeShow(true)
      })
    },
    loadPro(info) {
      var cartList = this.cartProInfo.cartCompanies[0].cartProducts
      // 删除 重复的新商品
      for (const i in cartList) { if (cartList[i].productId === info.productId) { cartList.splice(i, 1) } }
      this.cartProInfo.cartCompanies[0].cartProducts = cartList
      this.$set(this.cartProInfo.cartCompanies[0], 'cartProducts', cartList)
      this.$nextTick(() => {
        // 替换当前商品
        var oldIndex = cartList.length <= this.proindex ? cartList.length - 1 : this.proindex
        this.$set(this.cartProInfo.cartCompanies[0].cartProducts, oldIndex, info)
        this.$forceUpdate()
        this.showSpu = false
        this.$refs['proCard' + this.proindex][0].updateMessage(info)
      })
    },
    closed() { this.showSpu = false }
  }
}
</script>

<style>
.cell_custom-title {
  color: #000;
}
.navtive_video {
  z-index: 10001;
  position: fixed;
  bottom: 5.5rem;
  right: 0;
}
</style>
